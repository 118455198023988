<template>
  <div class="setLive" style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <div class="setLive-title">
      <div class="setLive-title-title">{{titleList.title}}</div>
      <div class="setLive-title-address">
<!--        <div style="margin-right: 10px;">会议地址</div>-->
        <div style="margin-right: 10px;">
<!--          <a-dropdown>-->
<!--            <a-menu slot="overlay" @click="handleMenuClick">-->
<!--              <a-menu-item key="1">会议地址</a-menu-item>-->
<!--              <a-menu-item key="2">游客模式</a-menu-item>-->
<!--            </a-menu>-->
<!--            <a-button style="margin-left: 8px"> 会议地址 <a-icon type="caret-down" /> </a-button>-->
<!--          </a-dropdown>-->
          <a-select default-value="会议地址" style="width: 120px" @change="handleMenuClick">
            <a-select-option value="1">
              会议地址
            </a-select-option>
            <a-select-option value="2">
              游客模式
            </a-select-option>
          </a-select>
        </div>
        <div style="width: 80%;">
          <a-input
              id="copy"
              v-model="targetUrl_top"
              :disabled="true"
              style="width: 100%"
              placeholder="请输入会议地址" />
        </div>
        <div style="color: #1374ce;margin-left: 10px;">
          <a-icon @click="copy" type="copy" />
        </div>
      </div>
    </div>
    <div class="setLive-top">
      <div class="setLive-top-content" style="margin-left: 50px">
        <div style="width: 50px">标题:</div>
        <div style="width: 80%">
          <a-input
              v-model="searchForm.title"
              style="width: 200px"
              placeholder="请输入标题"/>
        </div>
      </div>
      <div class="setLive-top-content" >
        <div style="width: 50px">日期:</div>
        <div style="width: 80%">
          <a-date-picker
              :allowClear="false"
              v-model="searchForm.time"
              @change="onChange" />
        </div>
      </div>
      <div class="setLive-top-content">
        <div>
          <a-button type="primary" @click="seachBtn">查询</a-button>
          <a-button @click="restCloseBtn" style="margin-left: 15px">重置</a-button>
        </div>
      </div>
    </div>
    <div class="setLive-table">
      <div class="setLive-table-content">
        <div>
          <a-button
              @click="addBtn"
              style="width: 100px"
              type="primary">新增</a-button>
        </div>
        <div style="margin-top: 15px">
<!--          :data-source="tablelist">-->
          <a-table
              :pagination="false"
              :columns="columns"
              :rowKey='record=>record.id'
              :data-source="tablelist">
            <span slot="action" slot-scope="text, record">
              <a-dropdown>
                <a-menu slot="overlay" >
                  <a-menu-item  @click="playbackBtn(record.replayIsOpen,record.id,record)" key="1">
                    回放
                  </a-menu-item>
<!--                  <a-menu-item @click="onWayBtn(record.loginType,record.id)" key="2">-->
<!--                    登录方式-->
<!--                  </a-menu-item>-->
                  <a-menu-item @click="EnterpriseBtn(record.isEnterprise,record.id,record)" key="2">
                    企业鸣谢
                  </a-menu-item>
<!--                  <a-menu-item @click="catalogueBtn(record.isEnterprise,record.id,record)" key="4">-->
<!--                    目录-->
<!--                  </a-menu-item>-->
<!--                  <a-menu-item @click="discussionBtn(record.isEnterprise,record.id,record)" key="5">-->
<!--                    讨论-->
<!--                  </a-menu-item>-->
                </a-menu>
<!--                <a-button type="primary" style="margin-left: 8px"> 操作 <a-icon type="down" /> </a-button>-->
                <a class="ant-dropdown-link" > 操作 <a-icon type="down" /></a>
              </a-dropdown>
              <a style="margin-left: 10px" @click="restBtn(record)">修改</a>
              <a-popconfirm
                  style="margin-left: 10px"
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delConfirm(record.id)"
                  @cancel="delCancel"
              ><a style="margin-left: 10px">删除</a></a-popconfirm>
            </span>
            <!--协议设置-->
            <span slot="protocolIsOpen" slot-scope="text, record">
              <a @click="dealBtn(record.protocolIsOpen,record.id,record)"
                 v-if="record.protocolIsOpen?'1':'0'">
                {{ record.protocolIsOpen?'已开启':'已关闭' }}
              </a>
            </span>
            <!--地址-->
            <span slot="address" slot-scope="text, record">
              <a @click="addresBtn(record)">查看地址</a>
            </span>


            <span slot="endTime" slot-scope="text, record">
              <div>
                {{(record.startTime).slice(11,19)}}~{{(record.endTime).slice(11,19)}}
              </div>
            </span>
            <span slot="liveDate" slot-scope="text, record">
              <div>
                {{record.liveDate}}
              </div>
            </span>

            <!--登陆方式 -->
            <span slot="loginType" slot-scope="text, record">
<!--              登录类型phone_code:手机号验证码   name_hospital:姓名医院-->
              <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'phone_code'">
                手机号验证码
              </a>
              <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'name_hospital'">
                医院姓名
              </a>
              <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'none_login'">
                无登录
              </a>
            </span>
<!--            &lt;!&ndash;回放&ndash;&gt;-->
<!--            <span slot="replayIsOpen" slot-scope="text, record">-->
<!--              <a-->
<!--                  @click="playbackBtn(record.replayIsOpen,record.id,record)"-->
<!--                  v-if="record.replayIsOpen?'1':'0'">-->
<!--                {{ record.replayIsOpen?'已开启':'已关闭' }}-->
<!--              </a>-->
<!--            </span>-->
<!--             &lt;!&ndash; 企业鸣谢&ndash;&gt;-->
<!--            <span slot="EnterpriseCredit" slot-scope="text, record">-->
<!--              <a @click="EnterpriseBtn(record.isEnterprise,record.id,record)"-->
<!--                 v-if="record.isEnterprise?'1':'0'">-->
<!--                {{ record.isEnterprise?'已开启':'已关闭' }}-->
<!--              </a>-->
<!--            </span>-->
            <!--是否开启-->
            <span slot="isOpen" slot-scope="text, record">
              <a-switch
                  :checked="record.isOpen ===1 ? true:false"
                  @click="tableIsOpen(record.isOpen === 1? 0 : 1,record.id)"
                  checked-children="开"
                  un-checked-children="关"  />
            </span>
          </a-table>
        </div>
      </div>
      <div style="width: 100%;height: 50px;">
        <div style="float: right;margin-right: 20px;margin-top: 15px">
          <a-pagination
              @change="listChange"
              :total="tableCount" />
        </div>
      </div>
    </div>
<!----------------------------------------对话框------------------>
     <!--    新增-->
    <a-modal
        :width="700"
        v-model="visible"
        title="新增"
        @ok="onSubmit"
        @cancel="addClose">
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="cover_url" label="详情封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upDetailsCover"
                  ref="deyails" />
            </div>
            <div
                class="upload_deyails">
              <img
                  v-if="form.cover_url"
                  alt=""
                  style="width: 100%;height:100%;"
                  :src="form.cover_url"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>
        </a-form-model-item>
        <a-form-model-item prop="desc_image_url" label="详情海报">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upPosterCover"
                  ref="descImageUrl" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="form.desc_image_url"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="form.desc_image_url"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>
        </a-form-model-item>
<!--        <a-form-model-item prop="" label="详情显示名称">-->
<!--            <a-tooltip placement="top">-->
<!--              <template slot="title">-->
<!--                <span>中文和英文用#号隔开，不设置则默认中文</span>-->
<!--              </template>-->
<!--              <a-icon type="info-circle" />-->
<!--            </a-tooltip>-->
<!--            <a-input style="width: 250px;margin-left: 5px" placeholder="详情#detail"/>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="直播日期" prop="live_date">
          <a-date-picker
              v-model="form.live_date"
              style="width: 250px"
              @change="dateChange" />
        </a-form-model-item>
        <a-form-model-item label="直播开始时间" prop="live_start_time">
          <a-tooltip placement="top">
            <template slot="title">
              <span>直播实际开始时间，用于直播间倒计时</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-time-picker
              :allowClear="false"
              style="width: 200px;margin-left: 5px"
              :value="moment(form.live_start_time, 'HH:mm:ss')"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="selectTime">
          </a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="推流开始时间" prop="start_time">
          <a-time-picker
              :allowClear="false"
              style="width: 200px"
              :value="moment(form.start_time, 'HH:mm:ss')"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="selectTime1">
          </a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="推流结束时间" prop="end_time">
          <a-time-picker
              :allowClear="false"
              style="width: 200px"
              :value="moment(form.end_time, 'HH:mm:ss')"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="selectTime2">
          </a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-textarea
              v-model="form.title"
              placeholder="请输入标题"
              :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </a-form-model-item>
        <a-form-model-item label="显示时间" prop="show_time">
          <a-input
              v-model="form.show_time"
              placeholder="请输入显示直播时间"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--    编辑-->
    <a-modal
        :width="700"
        v-model="visibleRest" title="编辑"
        @ok="onRestSubmit">
      <a-form-model
          ref="restruleForm"
          :model="restForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="cover_url" label="详情封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upRestDetailsCover"
                  ref="deyailsRest" />
            </div>
            <div
                class="upload_deyails">
              <img
                  v-if="restForm.cover_url"
                  alt=""
                  style="width: 100%;height:100%;"
                  :src="restForm.cover_url"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>
        </a-form-model-item>
        <a-form-model-item label="详情海报">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upEditPosterCover"
                  ref="editPoster" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="restForm.desc_image_url"
                  alt=""
                  style="width: 100%;height:auto;"
                  :src="restForm.desc_image_url"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>
        </a-form-model-item>
<!--        <a-form-model-item prop="" label="详情显示名称">-->
<!--          <a-tooltip placement="top">-->
<!--            <template slot="title">-->
<!--              <span>中文和英文用#号隔开，不设置则默认中文</span>-->
<!--            </template>-->
<!--            <a-icon type="info-circle" />-->
<!--          </a-tooltip>-->
<!--          <a-input style="width: 250px;margin-left: 5px" placeholder="详情#detail"/>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="直播开始时间" prop="live_start_time">
          <a-tooltip placement="top">
            <template slot="title">
              <span>直播实际开始时间，用于直播间倒计时</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-time-picker
              :allowClear="false"
              style="width: 200px;margin-left: 5px"
              :value="moment(restForm.live_start_time, 'HH:mm:ss')"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="selectTime_rest">
          </a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-textarea
              v-model="restForm.title"
              placeholder="请输入标题"
              :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </a-form-model-item>
        <a-form-model-item label="显示时间" prop="show_time">
          <a-input
              v-model="restForm.show_time"
              placeholder="请输入显示直播时间"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--    登陆方式-->
    <a-modal
        :width="500"
        v-model="visibleOnWay"
        title="登陆方式(设置登陆方式)"
        @ok="onWaySubmit">
      <a-form-model
          :model="onWayForm"
          :label-col="labelColLogin"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="选择登陆方式">
          <a-radio-group v-model="onWayForm.onWay" @change="selectLoginChange">
          <a-radio :style="radioStyle" value="phone_code">
            手机号验证码登录
          </a-radio>
          <a-radio :style="radioStyle" value="name_hospital">
            姓名医院登录（游客模式）
          </a-radio>
<!--          <a-radio :style="radioStyle" value="none_login">-->
<!--              无登录（游客模式）-->
<!--          </a-radio>-->
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-show="typeLoginStatus" label="选择完善身份信息">
          <a-radio-group v-model="onWayForm.status" @change="selectStatusChange">
            <a-radio :style="radioStyle" :value='1'>
              是
            </a-radio>
            <a-radio :style="radioStyle" :value='0'>
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
<!--        <a-form-model-item v-show="!typeLoginStatus">-->
<!--          <div style="width: 200px;margin-left: 32%">-->
<!--            <span>显示科室：</span>-->
<!--            <a-switch-->
<!--                :checked="isOpen ===1 ? true:false"-->
<!--                @click="officeIsOpen(isOpen === 1? 0 : 1)"-->
<!--                checked-children="开"-->
<!--                un-checked-children="关"  />-->
<!--          </div>-->
<!--          <div style="width: 200px;margin-left: 32%">-->
<!--            <span>显示职称：</span>-->
<!--            <a-switch-->
<!--                :checked="isOpen ===1 ? true:false"-->
<!--                @click="technicalIsOpen(isOpen === 1? 0 : 1)"-->
<!--                checked-children="开"-->
<!--                un-checked-children="关"  />-->
<!--          </div>-->
<!--          <div style="width: 200px;margin-left: 27%">-->
<!--            <span>显示手机号：</span>-->
<!--            <a-switch-->
<!--                :checked="isOpen ===1 ? true:false"-->
<!--                @click="telIsOpen(isOpen === 1? 0 : 1)"-->
<!--                checked-children="开"-->
<!--                un-checked-children="关"  />-->
<!--          </div>-->
<!--        </a-form-model-item>-->
      </a-form-model>
    </a-modal>
    <!--  警告框  -->
    <a-modal
        :visible="visibleModal"
        @ok="handleModal"
        @cancel="handleModal"
    >
      <span>直播间登录方式必须是【手机号验证码登录】，且完善身份信息为【是】，才可开启时长</span>
    </a-modal>
    <!--    时长-->
    <a-modal
        :width="500"
        v-model="visibleDuration"
        title="时长(设置时长)"
        @ok="durationSubmit">
      <a-form-model
          :model="durationForm"
          :label-col="labelColLogin"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="状态">
          <a-switch
              :checked="durationForm.duration_is_open!==1 ? false:true"
              @click="switchChange(durationForm.duration_is_open === 1? 0 : 1)"
              checked-children="开"
              un-checked-children="关"  />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
              v-model="durationForm.duration_remark"
              placeholder="请输入备注"
              :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--  回放  -->
    <a-drawer
        title="回放设置"
        :width="680"
        :visible="visiblePlayback"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormPlayback"
    >
      <div style="width: 600px">
        <div style="display: flex">
          <div style="color: black;font-size: 16px">状态:</div>
          <div style="margin-left: 15px">
            <a-switch
                :checked="PlaybackForm.replay_is_open !==1 ? false:true"
                @click="switchPlaybackChange(PlaybackForm.replay_is_open === 1? 0 : 1)"
                checked-children="开"
                un-checked-children="关"  />
          </div>
        </div>
        <div style="margin-top: 10px">
          <a-button type="primary" @click="addVideoBtn">新增</a-button>
        </div>
        <div>
          <a-table
              style="margin-top: 15px"
              :columns="columnsPlayback"
              :data-source="PlaybackTableData"
              :rowKey='record=>record.id' >
            <template
                v-for="col in ['name']"
                :slot="col"
                slot-scope="text, record, index"
            >
              <div :key="col">

                <div v-if="record.addIsShow">
                  <a-select
                      @popupScroll="popupScroll_addVideoBtn"
                      v-if="record.editable"
                      show-search
                      placeholder="输入视频标题关键字，从视频库选择"
                      option-filter-prop="children"
                      style="width: 200px"
                      @search="select_add_search"
                  ><a-select-option
                      v-for="role in videoList"
                      @click="select_add(role.title,role.id)"
                      :value="role.id">
                    {{role.title}}
                  </a-select-option>
                  </a-select>

                  <template  v-if="!record.editable">
                    <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                      {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                    </a>
                  </template>
                </div>

                <div v-else>
                  <a-input
                      v-if="record.editable"
                      style="margin: -5px 0"
                      :value="text"
                      @change="e => handleChange(e.target.value, record.id, col)"
                  />
                  <template  v-if="!record.editable">
                    <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                      {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                    </a>
                  </template>
                </div>

              </div>
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <!--保存取消-->
                <span v-if="record.editable">
              <a @click="() => save(record.id,record)">保存</a>
              <a-popconfirm
                  ok-text="是"
                  cancel-text="否"
                  style="margin-left: 5px"
                  title="是否取消?"
                  @confirm="() => cancel(record.id,record)">
                <a>取消</a>
              </a-popconfirm>
            </span>
                <!--删除-->
                <span v-if="!record.editable">
              <a-popconfirm
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirmDel(record.id)"
              ><a>删除</a>
              </a-popconfirm>
            </span>
              </div>
            </template>
          </a-table>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '1px' }" @click="resetFormPlayback">
          取消
        </a-button>
<!--        <a-button type="primary" @click="onSubmitPlayback">-->
<!--          确认-->
<!--        </a-button>-->
      </div>
    </a-drawer>
    <!--  协议设置  -->
    <a-drawer
        title="协议设置"
        :width="680"
        :visible="visibleDeal"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormDeal"
    >
      <div style="width: 600px">
        <div style="display: flex">
          <div style="color: black;font-size: 16px">状态:</div>
          <div style="margin-left: 15px">
            <a-switch
                :checked="dealForm.protocol_is_open !==1 ? false:true"
                @click="switchDealChange(dealForm.protocol_is_open === 1? 0 : 1)"
                checked-children="开"
                un-checked-children="关"  />
          </div>
        </div>
        <div style="margin-top: 20px">
          <div ref="editor" >
          </div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormDeal">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmitDeal">
          确认
        </a-button>
      </div>
    </a-drawer>
    <!--  地址  -->
    <a-drawer
        title="地址"
        :width="450"
        :visible="visibleAdress"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormAddress"
    >
      <div style="width: 450px">
        <div>
          <div style="margin-left: 15px">
            推流: 服务器
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address1"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 30px">
              <a-icon @click="copySum(addressForm.address1)" type="copy" />
            </div>
          </div>

        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            推流: 串流密钥
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address2"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address2)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：rtmp
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address3"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address3)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：flv
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address4"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address4)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：m3u8
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address5"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 50px">
              <a-icon @click="copySum(addressForm.address5)" type="copy" />
            </div>
          </div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormAddress">
          关闭
        </a-button>
      </div>
    </a-drawer>
    <!--企业鸣谢-->
    <a-modal
        @cancel="()=>{ this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''}"
        width="500px"
        v-model="content_Enterprise_zone_visible"
        title="企业鸣谢">
      <div>
        <span>状态：</span>
        <a-switch
            v-model="Enterprise_zoneForm.isOpen_list === 1 ? true:false"
            checked-children="开"
            un-checked-children="关"
            @click="RCYL_Enterprise_zone_change_isOpen(Enterprise_zoneForm.isOpen_list === 1 ?0:1)"
            default-checked />
      </div>
      <div style="margin-top: 10px">
        <span>名称：</span>
        <a-tooltip placement="top">
          <template slot="title">
            <span>提示提示提示提示提示</span>
          </template>
          <a-icon type="info-circle" />
        </a-tooltip>
        <a-input style="width: 250px;margin-left: 5px" placeholder="请输入名称"/>
      </div>
      <div style="margin-top: 15px;display: flex">
        <span style="width: 50px">图片：</span>
        <div>
          <div style="display: flex;width: 400px;">
            <div class="uploadImg" >
              <input
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_RCYL_Enterprise_zone_image"
                  ref="content_RCYL_Enterprise_zone" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="Enterprise_zoneForm.image"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="Enterprise_zoneForm.image"
              />
            </div>
          </div>
          <div style="color: #a1a1a1;margin-top: 5px">注:尺寸 宽640，大小不超过1M</div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="()=>{ this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''}">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="content_Enterprise_zone_Ok">
          确认
        </a-button>
      </div>
    </a-modal>
      <!--目录-->
      <a-modal
          @cancel="()=>{ this.content_catalogue_visible = false}"
          width="500px"
          v-model="content_catalogue_visible"
          title="目录">
        <div style="margin-top: 10px">
          <span>名称：</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>提示提示提示提示提示</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-input style="width: 250px;margin-left: 5px" placeholder="请输入名称"/>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="()=>{ this.content_catalogue_visible = false}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_catalogue_true">
            确认
          </a-button>
        </div>
      </a-modal>
      <!--讨论-->
      <a-modal
          @cancel="()=>{ this.content_discussion_visible = false}"
          width="500px"
          v-model="content_discussion_visible"
          title="企业鸣谢">
        <div>
          <span>状态：</span>
          <a-switch
              v-model="discussion.isOpen_list === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="RCYL_discussion_change_isOpen(Enterprise_zoneForm.isOpen_list === 1 ?0:1)"
              default-checked />
        </div>
        <div style="margin-top: 10px">
          <span>名称：</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>提示提示提示提示提示</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-input v-model="discussion.name" style="width: 250px;margin-left: 5px" placeholder="请输入名称"/>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="()=>{ this.content_Enterprise_zone_visible = false}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_discussion_true">
            确认
          </a-button>
        </div>
      </a-modal>
    <!--回放开启对话框-->
      <a-modal
          :width="680"
          v-model="roomIsOpen"
          title="注意"
          @ok="roomIsOpenSubmit"
          @cancel="roomIsOpenClose">
        <a-icon style="font-size:25px;color: #fa8358;margin-top: 5px" type="exclamation-circle" />
        <span style="margin-left: 10px;">直播间必须在关闭状态下才可以开启回访，是否关闭直播间？</span>
      </a-modal>
<!-- 底部   -->
    <div style="height: 60px"></div>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div
          style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import {update} from "../../utils/update";
import {difference} from "../../utils/timeDifference";
import {
  delSetLiveList,
  getListContent,
  getOneLiveList,
  getSetLiveList,
  putSetLiveList
} from "../../service/liveMeet_api";
import E from "wangeditor";
import {
  getLiveOne,
  getplaybackList,
  postSetLive,
  putSetLive,
  putSetLive_xieyi,
  putTitle
} from "../../service/liveMeet_api2";
import {get_video_list, post_video_list, put_RCYL_isOpen} from "../../service/MedicalConference";
const columnsPlayback = [
  {
    title: '视频Id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '标题',
    dataIndex: 'name',
    width: '25%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '时长',
    dataIndex: 'duration',
    width: '25%',
    scopedSlots: { customRender: 'duration' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const PlaybackTableData =[];
export default {
  data(){
    // this.cacheData = PlaybackTableData.map(item => ({ ...item }));
    return{
      targetUrl_top:'',
      tableRow:{},
      roomIsOpen:false,
      RCYL_Enterprise_zone_isOpen_id:'',
      spinning:false,
      content_Enterprise_zone_visible:false,
      content_discussion_visible:false,
      content_catalogue_visible:false,
      Enterprise_zoneForm:{
        isOpen_list:0,
        image:'',
      },
      discussion:{
        isOpen_list:0,
        name:'',
      },
      dataList:[],
      cacheData:[],
      is_delete:null,
      visibleModal:false,
      PlaybackTableData,
      columnsPlayback,
      editingKey: '',
      // open1: false,
      // open2: false,
      open3: false,
      open4: false,
      visible:false,
      visibleRest:false,
      visibleOnWay:false,
      visibleDuration:false,
      visiblePlayback:false,
      visibleDeal:false,
      searchForm:{
        title:'',
        time:''
      },
      columns:[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: "6%",
          ellipsis: true,
        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width: "15%",
          scopedSlots: { customRender: 'titleData' },
          ellipsis: true,
        },
        {
          title: '直播日期',
          dataIndex: 'liveDate',
          width: "10%",
          scopedSlots: { customRender: 'liveDate' },
        },
        // {
        //   title: '显示时间',
        //   dataIndex: 'showTime',
        //   width: "10%",
        //   // scopedSlots: { customRender: 'liveDate' },
        // },
        {
          title: '直播时间',
          dataIndex: 'endTime',
          key: 'endTime',
          width: "13%",
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: '协议',
          dataIndex: 'protocolIsOpen',
          key: 'protocolIsOpen',
          width: "7%",
          scopedSlots: { customRender: 'protocolIsOpen' },
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          width: "8%",
          scopedSlots: { customRender: 'address' },
        },
        // {
        //   title: '回放',
        //   dataIndex: 'replayIsOpen',
        //   key: 'replayIsOpen',
        //   width: "7%",
        //   scopedSlots: { customRender: 'replayIsOpen' },
        // },
        {
          title: '登录方式',
          dataIndex: 'loginType',
          key: 'loginType',
          width: "12%",
          scopedSlots: { customRender: 'loginType' },
        },
        // {
        //   title: '企业鸣谢',
        //   dataIndex: 'isEnterprise',
        //   key: 'isEnterprise',
        //   width: "8%",
        //   scopedSlots: { customRender: 'EnterpriseCredit' },
        // },
        {
          title: '是否开启',
          dataIndex: 'isOpen',
          key: 'isOpen',
          width: "8%",
          scopedSlots: { customRender: 'isOpen' },
        },
        {
          title: '操作',
          key: 'action',
          width: "18%",
          scopedSlots: { customRender: 'action' },
        },
      ],
      data:[],
      labelCol: { span: 4},
      labelColLogin: { span: 8},
      wrapperCol: { span: 14 },
      form: {
        cover_url:'',
        desc_image_url:'',
        live_date:undefined,
        live_start_time:'00:00:00',
        start_time:'00:00:00',
        end_time:'00:00:00',
        title:'',
        live_metting_id:'',
        show_time:'',
        created_by_id:'',
      },
      rules: {
        cover_url: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        desc_image_url: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        live_date: [{ required: true, message: '请选择日期', trigger: 'change' }],
        live_start_time: [{ required: true, message: '请选择日期', trigger: 'change' }],
        start_time: [{ required: true, message: '请选择日期', trigger: 'change' }],
        end_time: [{ required: true, message: '请选择日期', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        show_time: [{ required: true, message: '请输入显示直播时间', trigger: 'blur' }],
      },
      restForm: {
        cover_url:'',
        desc_image_url:'',
        live_start_time:'00:00:00',
        title:'',
        show_time:'',
      },
      onWayForm:{
        onWay:'',
        status:0,
      },
      durationForm:{
        duration_is_open:0,
        duration_remark:'',
      },
      dealForm:{
        protocol_is_open:0,
        protocol_content:'',
      },
      PlaybackForm:{
        replay_is_open:0,
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      titleList:[],
      tablelist:[],
      tableCount:null,
      page_no:'',
      page_size:'',
      typeLoginStatus:true,
      //内容与编辑器
      //富文本显示内容编辑
      // 默认情况下，显示所有菜单
      editor_menus:[
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo',
      ],
      editor:null,
      // dealForm:{
      //   isShow:1,
      //   include:''
      // },
      addressForm:{
        address1:'',
        address2:'',
        address3:'',
        address4:'',
        address5:'',
      },
      visibleAdress:false,
      listId:'',
      timeList:[],
      durationId:'',
      dealId:'',
      playbackId:'',
      typeId:'',
      isOpen:0,
      OneLiveList:[],
      addIsShow:false,
      videoList:[],
      videoListId:'',
      addVideoBtn_select_page_no:1,
      selectTitle:'',
      liveDate_new:'',
    }
  },
  mounted() {
    this.getTitle()
    this.getTableList()
  },
  computed:{
    liveImg(){
      return this.$store.state.liveImg
    }
  },
  methods:{
    moment,
    //新增对话框富文本编辑器
    divRedio(){
      //初始化富文本编辑器
      const editor = new E(this.$refs.editor)
      this.editor = editor
      editor.config.height = 300
      //配置菜单栏
      editor.config.menus = this.editor_menus
      // //限制一次最多能传几张图片
      // editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      editor.create()
    },
    //获取直播标题与会议地址
    async getTitle() {
      this.spinning = true
      let id = this.$route.query.live_metting_id
      const response = await getListContent(id)
      if(response.code === 0){
        this.titleList = response.data
        if(this.targetUrl_top === ''){
          this.targetUrl_top = response.data.targetUrl
        }
      }else {
        this.$message.warning("出错，请联系管理员！"+response.message)
      }
      this.spinning = false
    },
    //获取设置直播列表
    async getTableList(page_no,page_size,title,live_date) {
      this.spinning = true
     let live_metting_id =  this.$route.query.live_metting_id
      let data = {
        page_no:page_no,
        page_size:page_size,
        title:title,
        liveDate:live_date,
        liveMettingId:live_metting_id
      }
      const response = await getSetLiveList(data)
      if(response.code === 0){
        this.tablelist = response.data.rows
        this.tableCount = response.data.count
      }else {
        this.$message.warning("出错，请联系管理员！"+response.message)
      }
      this.spinning = false
    },
    //获取单个直播间
    async getOneLisat(id) {
      this.spinning = true
      const response = await getOneLiveList(id)
      if (response.code === 0) {
        this.OneLiveList = response.data
      } else {
        this.$message.warning("出错，请联系管理员！" + response.message)
      }
      this.spinning = false
    },
    //分页
    listChange(page_no,page_size){
      let form = this.searchForm
      this.page_no = page_no
      this.page_size = page_size
      this.getTableList(page_no,page_size,form.title,form.time)
    },
    onChange(){
      this.searchForm.time = moment(this.searchForm.time).format('YYYY-MM-DD')
    },
    dateChange(date, dateString){
      this.form.live_date = dateString
    },
    onClose(){
      this.visible = false
    },
    async upDetailsCover() {
      let inputDOM = this.$refs.deyails.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.form.cover_url = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async upPosterCover() {
      let inputDOM = this.$refs.descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 3145728) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.form.desc_image_url = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于3M的图片！")
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    addClose(){
      // this.form = {}
      Object.keys(this.form).forEach(key => this.form[key]= '');
      this.form.live_date = undefined
      this.form.start_time = '00:00:00'
      this.form.end_time = '00:00:00'
      this.form.live_start_time = '00:00:00'

    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          // console.log(this.form)
          this.form.live_metting_id = parseInt(this.$route.query.live_metting_id)
          this.form.end_time = this.form.live_date + ' ' + this.form.end_time
          this.form.start_time = this.form.live_date + ' ' + this.form.start_time
          this.form.live_start_time = this.form.live_date + ' ' + this.form.live_start_time
          //获取本地存储信息，并转换为Json格式
          const userData = JSON.parse(window.localStorage.getItem("user_info"))
          this.form.created_by_id = userData.id
          const response = await postSetLive(this.form)
          if (response.code === 0) {
            this.$message.success("添加成功")
            Object.keys(this.form).forEach(key => this.form[key]= '');
            this.form.live_date = undefined
            this.form.start_time = '00:00:00'
            this.form.end_time = '00:00:00'
            this.form.live_start_time = '00:00:00'
            await this.getTableList()
            this.visible = false;
          } else {
            this.$message.warning("添加失败")
          }
        } else {
          this.$message.warning("有空~~")
          return false;
        }
      });
    },
    addBtn(){
      this.visible = true
      this.form.cover_url = this.liveImg.detailCoverUrl
      this.form.desc_image_url = this.liveImg.descImageUrl
      //获取时间列表
      this.timeList = this.titleList.dateRange
    },
    selectTime_rest(date, dateString){
      this.restForm.live_start_time = dateString
    },
    selectTime(date, dateString){
      this.form.live_start_time = dateString
    },
    selectTime1(date, dateString){
      this.form.start_time = dateString
    },
    selectTime2(date, dateString){
      this.form.end_time = dateString
    },
    //修改
    async restBtn(row) {
      // 判断当前时间是不是大于要修改的时间
      if(row.startTime !== undefined && row.startTime !== null && row.startTime !== ''){
        let date1 = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        // if(difference(date1,row.startTime) <= 10){
          await this.getOneLisat(row.id)
          this.visibleRest = true
          this.listId = row.id
          let form = this.restForm
          let list = this.OneLiveList
          //赋值
          this.liveDate_new = list.liveDate
          if(list.live_start_time){
            let arr = list.live_start_time
            form.live_start_time = arr.slice(11,19)
          }
          form.desc_image_url = list.descImageUrl
          form.cover_url = list.coverUrl
          form.title = row.title
          form.show_time = list.showTime
        // }
      }
    },
    async upRestDetailsCover() {
      let inputDOM = this.$refs.deyailsRest.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.restForm.cover_url = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async upEditPosterCover() {
      let inputDOM = this.$refs.editPoster.files[0];
      //判断图片大小
      if (inputDOM.size < 3145728) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.restForm.desc_image_url = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于3M的图片！")
      }
    },
    dateRestChange(value){
      this.restForm.live_date = value
    },
    //修改协议公用
    async modification_xieyi(id,data) {
      const response = await putSetLive_xieyi(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },
    //修改外包公用
    async modification(id,data) {
      const response = await putSetLive(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },
    //修改公用
    async modification_icu(id,data) {
      const response = await putSetLiveList(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },
    onRestSubmit() {
      this.restForm.live_start_time = this.liveDate_new  + ' ' + this.restForm.live_start_time
      this.modification(this.listId, this.restForm)
      this.visibleRest = false;
    },
    //登陆方式
    async onWayBtn(type, id) {
      //获取是否完善信息
      await this.getOneLisat(id)
      this.visibleOnWay = true
      this.onWayForm.onWay = type
      this.typeId = id
      if (type === 'phone_code') {
        this.typeLoginStatus = true
        this.onWayForm.status = this.OneLiveList.isNeedPerfectInfo
      } else {
        this.typeLoginStatus = false
      }
    },
    //登陆方式对话框确认按钮
    onWaySubmit(){
        const data = {
          loginType:this.onWayForm.onWay,
          isNeedPerfectInfo:this.onWayForm.status,
        }
        //修改
        this.modification_icu(this.typeId,data)
        this.getTableList(this.page_no,this.page_size)
        this.visibleOnWay = false
    },
    selectLoginChange(){
      let onWay = this.onWayForm.onWay
      if(onWay === 'phone_code'){
        this.typeLoginStatus = true
      }else {
        this.typeLoginStatus = false
      }
    },
    handleModal() {
        this.visibleModal = false;
    },
    selectStatusChange(){
    },
    switchChange(checked){
      this.durationForm.duration_is_open = checked
    },
    durationBtn(isOpen,id){
      this.visibleDuration = true
      this.durationForm.duration_is_open = parseInt(isOpen)
      this.durationId = id
    },
    //选择时长确认
    durationSubmit(){
     if(this.durationForm.duration_remark !== ''){
       this.modification(this.durationId,this.durationForm)
       this.visibleDuration = false
       let form = this.searchForm
       this.getTableList(this.page_no,this.page_size,form.title,form.time)
       this.durationForm.duration_remark = ''
     }else {
       this.$message.warning("请填写备注")
     }
    },
    //回访设置
    playbackBtn(isOpen, id,row) {
      this.tableRow = row
      this.visiblePlayback = true
      this.playbackId = id
      this.PlaybackForm.replay_is_open = parseInt(isOpen)
      // //获取回放对话框中的table
      this.delModelList(id)
    },
    async delModelList(testId) {
      const response = await getplaybackList(testId)
      if (response.code === 0) {
        this.dataList = response.data.video_list
        let list = this.dataList
        //每次请求前清空
        this.PlaybackTableData = []
        // if(this.data === []){
        for (let i = 0; i < list.length; i++) {
          this.PlaybackTableData.push({
            id: '',
            name: '',
            duration: '',
            addIsShow:0,
          })
          this.PlaybackTableData[i].name = list[i].title
          this.PlaybackTableData[i].id = list[i].id
          this.PlaybackTableData[i].duration = list[i].duration
        }
        // }
        this.cacheData = this.PlaybackTableData.map(item => ({...item}));

      } else {
        this.$message.warning("失败，请联系管理员" + response.message)
      }
    },
    resetFormPlayback(){
      this.visiblePlayback = false
      this.PlaybackTableData = []
      this.videoList = []
    },
    async onSubmitPlayback() {
      this.PlaybackTableData = []
      this.visiblePlayback = false
    },


    handleChange(value, id, column) {
      const newData = [...this.PlaybackTableData];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.PlaybackTableData = newData;
      }
    },
    edit(id) {
      const newData = [...this.PlaybackTableData];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.PlaybackTableData = newData;
      }
    },
    async putLive(id, data) {
      const response = await putTitle(id, data)
      if (response.code === 0) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },
    //保存
    async save(id, row) {
      if (!row.addIsShow) {
        const newData = [...this.PlaybackTableData];
        const newCacheData = [...this.cacheData];
        const target = newData.find(item => id === item.id);
        const targetCache = newCacheData.find(item => id === item.id);
        if (target && targetCache) {
          delete target.editable;
          this.PlaybackTableData = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
        }
        this.editingKey = '';
        //修改标题
        const PlaybackTableData = {
          title: row.name
        }
        await this.putLive(id, PlaybackTableData)
      } else {
        //选择的视频库id,和列表id
        let videoManegeId = this.videoListId
        let streamId = this.playbackId
        await this.postVideo(streamId, videoManegeId)
        row.editable = 0
        row.addIsShow = 0
        this.editingKey = '';
        //  刷新列表
        await this.delModelList(streamId)
      }
    },
    async postVideo(streamId,videoManegeId) {
      const response = await post_video_list(streamId,videoManegeId)
      if (response.code === 0) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错" + response.message)
      }
    },
    //取消
    cancel(id,row) {
      if(!row.addIsShow) {
        const newData = [...this.PlaybackTableData];
        const target = newData.find(item => id === item.id);
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.find(item => id === item.id));
          delete target.editable;
          this.PlaybackTableData = newData;
        }
      }else {
        this.PlaybackTableData.pop()
      }
    },
    async confirmDel(id) {
      this.is_delete = 1
      const data = {
        is_delete: this.is_delete
      }
      await this.putLive(id, data)
      //  清空
      this.PlaybackTableData = []
      await this.delModelList(this.playbackId)
    },
    cancelDel(){
      this.is_delete = 0
    },
    //HTML反转义
    HTMLDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    //获取外包接口单个详情
    async getOneLiveList(id) {
      const response = await getLiveOne(id)
      if(response.code === 0){
        // this.dealForm.protocol_content = this.editor.txt.html(`${response.data.protocol_content}`)
        this.editor.txt.html(response.data.protocol_content);
        this.editor.txt.html(this.HTMLDecode(response.data.protocol_content))

      }else {
        this.$message.warning("失败"+response.message)
      }
    },
    // 协议设置
    dealBtn(isOpen,id,row){
      this.visibleDeal = true
      this.dealId = id
      this.dealForm.protocol_is_open = parseInt(isOpen)
      this.getOneLiveList(row.id)
      //等待dom元素更新
      this.$nextTick(() => {
        //判断文本编辑器是否创建，创建就销毁，防止重复创建
        if(this.editor === null){
          //初始化富文本编辑器
          this.divRedio()
        }else {
          //销毁
          this.editor.destroy()
          this.divRedio()
        }
      })
    },
    resetFormDeal(){
      this.visibleDeal = false
    },
    onSubmitDeal(){
      this.dealForm.protocol_content = this.editor.txt.html()
      if(this.dealForm.protocol_content !== ''){
        this.modification_xieyi(this.dealId,this.dealForm)
        this.visibleDeal = false
        let form = this.searchForm
        this.getTableList(this.page_no,this.page_size,form.title,form.time)
        this.dealForm.protocol_content = ''
      }else {
        this.$message.warning("请填写内容")
      }
    },
    //查询按钮
    seachBtn(){
     let form = this.searchForm
      this.getTableList(this.page_no,this.page_size,form.title,form.time)
    },
    restCloseBtn(){
      let form = this.searchForm
      form.time = ''
      form.title = ''
      this.getTableList()
    },
    switchDealChange(checked){
      this.dealForm.protocol_content = this.editor.txt.html()
      if(this.dealForm.protocol_content !== ''
          && this.dealForm.protocol_content !== undefined
          && this.dealForm.protocol_content !== null){
        this.dealForm.protocol_is_open = checked
      }else {
        this.$message.warning("请先输入内容后在开启！")
      }
    },
    switchPlaybackChange(checked){
      // console.log(this.tableRow.isOpen)
      if(this.tableRow.isOpen === 0 || checked === 0){
        this.PlaybackForm.replay_is_open = checked
        const data = {
          replay_is_open:this.PlaybackForm.replay_is_open
        }
        this.modification(this.playbackId,data)
      }else {
        this.roomIsOpen = true
      }
    },
    //顶部复制
    copy () {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.targetUrl_top;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('success!')
      //移除input标签
      document.body.removeChild(input)
    },
    copySum (item) {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = item;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('success!')
      //移除input标签
      document.body.removeChild(input)
    },
    resetFormAddress(){
      this.visibleAdress = false
    },
    //地址打开对话框
    async addresBtn(list) {
      await this.getOneLisat(list.id)
      this.visibleAdress = true
      //传id，获取单个直播列表中的地址
      //直播地址赋值
      let form = this.addressForm
      let row = this.OneLiveList
      form.address1 = row.obsPushIp
      form.address2 = row.obsPushName
      form.address3 = row.pullRtmpIp
      form.address4 = row.pullFlvIp
      form.address5 = row.pullM3u8Ip
    },
    returnBtn(){
      window.history.back()
    },
    async delConfirm(id) {
      const response = await delSetLiveList(id)
      if(response.code === 0){
        this.$message.success("删除成功！")
        let form = this.searchForm
        await this.getTableList(this.page_no ,this.page_size,form.title,form.time)
      }else {
        this.$message.warning("失败请联系管理员！" + response.message)
      }
    },
    delCancel() {

    },
    async EnterpriseBtn(is_open, id, row) {
      this.RCYL_Enterprise_zone_isOpen_id = id
      await this.getOneLisat(id)
      this.content_Enterprise_zone_visible = true
      let list = this.OneLiveList
      let form = this.Enterprise_zoneForm
      this.Enterprise_zoneForm.isOpen_list = list.isEnterprise
      this.Enterprise_zoneForm.image = list.enterpriseImage
    },
    catalogueBtn(is_open, id, row){
      this.content_catalogue_visible = true
    },
    discussionBtn(is_open, id, row){
      this.content_discussion_visible = true
    },
    tableIsOpen(is_open,id){
      let data = {
        isOpen:is_open
      }
      this.modification_icu(id,data)
    },
    RCYL_Enterprise_zone_change_isOpen(isOpen){
      if(this.Enterprise_zoneForm.image !== undefined
          && this.Enterprise_zoneForm.image !== null
          && this.Enterprise_zoneForm.image !== ''){
        this.Enterprise_zoneForm.isOpen_list = isOpen
      }else {
        this.$message.warning('请先上传图片！')
      }
    },
    RCYL_discussion_change_isOpen(isOpen){
      // if(this.Enterprise_zoneForm.image !== undefined
      //     && this.Enterprise_zoneForm.image !== null
      //     && this.Enterprise_zoneForm.image !== ''){
      //   this.Enterprise_zoneForm.isOpen_list = isOpen
      // }else {
      //   this.$message.warning('请先上传图片！')
      // }
    },
    async content_RCYL_Enterprise_zone_image() {
      let inputDOM = this.$refs.content_RCYL_Enterprise_zone.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.Enterprise_zoneForm.image = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    //共用修改接口
    async rest_RCYL_isOpen(id,data) {
      const response = await put_RCYL_isOpen(id,data)
      if(response.code === 0){
        this.$message.success("修改成功！")
        //  刷新列表
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      }else {
        this.$message.warning("失败！"+response.message)
      }

    },
    content_Enterprise_zone_Ok(){
      let form = this.Enterprise_zoneForm
      let data = {
        "isEnterprise":form.isOpen_list,
        "enterpriseImage":[form.image]
      }
      let id = this.RCYL_Enterprise_zone_isOpen_id
      this.rest_RCYL_isOpen(id,data)
      this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''
    },
    async addVideoList(page_no,value) {
      let data = {
        page_no:page_no,
        title:value,
      }
      const response = await get_video_list(data)
      if (response.code === 0) {
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.videoList.push({
            duration: list[i].duration,
            id: list[i].id,
            remark: list[i].remark,
            title: list[i].title,
          })
        }
      } else {
        this.$message.warning("失败"+response.message)
      }
    },
    //滚动条监听
    popupScroll_addVideoBtn(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.addVideoBtn_select_page_no++
        this.addVideoList(this.addVideoBtn_select_page_no,this.selectTitle)
      }
    },
    async addVideoBtn() {
      //获取视频库列表
      await this.addVideoList()
      this.PlaybackTableData.push({
        name: '',
        duration: '',
        editable: 1,
        addIsShow:1
      })
    },
    select_add_search(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.videoList = []
      this.selectTitle = value
      this.addVideoList('',this.selectTitle)
    },
    select_add(title,id){
      this.videoListId = id
    },

    async roomIsOpenSubmit() {
      //修改直播间为关闭状态
      let data = {
        isOpen: 0
      }
      await this.modification_icu(this.playbackId, data)
      this.roomIsOpen = false
      //修改回放为开启状态
      this.PlaybackForm.replay_is_open = 1
      const data2 = {
        replay_is_open: this.PlaybackForm.replay_is_open
      }
      await this.modification(this.playbackId, data2)
    },
    roomIsOpenClose(){
      this.roomIsOpen = false
    },
    handleMenuClick(value) {
      if(value === '2'){
        this.targetUrl_top  = this.targetUrl_top + '&memberId=78ce3cd0-e42b-11ea-8c91-23f1f183e1f9'
      }else {
        this.targetUrl_top  = this.titleList.targetUrl
      }
    },
    content_catalogue_true(){
      this.content_catalogue_visible = false
    },
    content_discussion_true(){
      this.content_discussion_visible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.setLive{
  .setLive-title{
    width: 100%;
    height: 60px;
    line-height: 50px;
    display: flex;
    border-bottom: 10px solid #f0f2f5;
    .setLive-title-title{
      font-size: 16px;
      font-weight: bold;
      margin-left: 20px;
      width: 50%;
    }
    .setLive-title-address{
      width: 50%;
      font-size: 16px;
      display: flex;
    }
  }
  .setLive-top{
    display: flex;
    height: 80px;
    border-bottom: 10px solid #f0f2f5;
    .setLive-top-content{
      display: flex;
      height: 80px;
      line-height: 80px;
      width: 33%;
    }
  }
  .setLive-table{
    width: 100%;
    .setLive-table-content{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_deyails{
  border: #DDDDDD 2px dashed;
  width: 220px;
  height: 130px;
  margin-left: 20px;
  text-align: center;
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width: 160px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}
</style>